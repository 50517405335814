import { Mountain } from "lucide-react";

const Header = () => (
  <header className="px-6 h-16 flex items-center justify-between shadow-lg">
    <a className="flex items-center text-white space-x-2" href="/">
      <Mountain className="h-8 w-8 text-black" />
      <span className="font-bold text-xl tracking-tight text-black">
        Art Seekr
      </span>
    </a>
  </header>
);

export default Header;
