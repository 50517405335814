import React from "react";
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ArrowUpIcon, ArrowDownIcon } from "lucide-react";
import { Mountain } from "lucide-react";

const timelineData = [
  { date: "2023-01-01", considered: 100, price: 50 },
  { date: "2023-02-01", considered: 120, price: 50 },
  { date: "2023-03-01", considered: 90, price: 60, priceIncrease: true },
  { date: "2023-04-01", considered: 150, price: 60 },
  { date: "2023-05-01", considered: 180, price: 60 },
  { date: "2023-06-01", considered: 130, price: 55, priceDecrease: true },
];

const dailySalesData = [
  { date: "2023-05-25", sales: 12 },
  { date: "2023-05-26", sales: 15 },
  { date: "2023-05-27", sales: 18 },
  { date: "2023-05-28", sales: 22 },
  { date: "2023-05-29", sales: 20 },
  { date: "2023-05-30", sales: 25 },
  { date: "2023-05-31", sales: 30 },
];

const promoCodes = [
  { code: "SUMMER10", usageCount: 45, date: "2023-06-15" },
  { code: "FLASH20", usageCount: 30, date: "2023-05-01" },
  { code: "NEWUSER", usageCount: 60, date: "2023-04-01" },
];

const ItemMetrics = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white text-black">
      <header className="px-6 h-16 flex items-center justify-between bg-white shadow-lg">
        <a className="flex items-center text-black space-x-2" href="/">
          <Mountain className="h-8 w-8 text-black" />
          <span className="font-bold text-xl tracking-tight">Art Seekr</span>
        </a>
      </header>

      <div className="container mx-auto p-6 bg-white text-black min-h-screen">
        <h1 className="text-4xl font-bold mb-8 text-black">
          Product Metrics: Caramel Iced Latte
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          {/* Left Column with Chart */}
          <div className="md:col-span-2">
            <div className="shadow-lg p-6 bg-gray-100 rounded-xl">
              <h2 className="text-xl font-semibold text-black mb-4">
                Purchase Metrics
              </h2>
              <div className="flex justify-between items-center mb-6">
                <div>
                  <div className="text-5xl font-extrabold">1,234</div>
                  <p className="text-sm text-gray-500">Total purchases</p>
                </div>
                <div className="text-right">
                  <div className="text-2xl font-semibold text-green-500 flex items-center">
                    +15% <ArrowUpIcon className="ml-1 w-5 h-5" />
                  </div>
                  <p className="text-sm text-gray-500">vs. last month</p>
                </div>
              </div>
              <div className="h-[220px]">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={dailySalesData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="sales"
                      stroke="#4F46E5"
                      fill="#6366F1"
                      fillOpacity={0.4}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          {/* Right Column with Image */}
          <div className="flex justify-center">
            <div className="flex shadow-lg rounded-xl bg-gray-100 overflow-hidden">
              <img
                src="https://mcdonalds.com.pk/wp-content/uploads/2022/08/Caramel-Iced-Latte.png"
                alt="Caramel Iced Latte"
                className="w-full h-auto object-cover transform transition-transform hover:scale-105"
              />
            </div>
          </div>
        </div>

        {/* Consideration Timeline */}
        <div className="shadow-lg p-6 bg-gray-100 rounded-xl mb-8">
          <h2 className="text-xl font-semibold text-black mb-4">
            Consideration Timeline
          </h2>
          <div className="h-[320px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={timelineData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="considered"
                  stroke="#4F46E5"
                  strokeWidth={2}
                />
                <Line
                  yAxisId="right"
                  type="stepAfter"
                  dataKey="price"
                  stroke="#10B981"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-4 text-sm text-gray-500 flex items-center justify-center space-x-6">
            <span className="flex items-center">
              <span className="inline-block w-3 h-3 bg-[#4F46E5] mr-2"></span>
              Consideration Count
            </span>
            <span className="flex items-center">
              <span className="inline-block w-3 h-3 bg-[#10B981] mr-2"></span>
              Price
            </span>
            <span className="flex items-center">
              <ArrowUpIcon className="w-4 h-4 text-red-500 mr-1" />
              Price Increase
            </span>
            <span className="flex items-center">
              <ArrowDownIcon className="w-4 h-4 text-green-500 mr-1" />
              Price Decrease
            </span>
          </div>
        </div>

        {/* Promo Code Table */}
        <div className="shadow-lg p-6 bg-gray-100 rounded-xl">
          <h2 className="text-xl font-semibold text-black mb-4">
            Promo Code Usage History
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full text-left text-black">
              <thead className="bg-gray-200">
                <tr>
                  <th className="p-3 text-gray-500">Promo Code</th>
                  <th className="p-3 text-gray-500">Usage Count</th>
                  <th className="p-3 text-gray-500">Date</th>
                </tr>
              </thead>
              <tbody>
                {promoCodes.map((promo, index) => (
                  <tr
                    key={index}
                    className="border-b border-gray-300 hover:bg-gray-200 transition-colors"
                  >
                    <td className="p-3">{promo.code}</td>
                    <td className="p-3">{promo.usageCount}</td>
                    <td className="p-3">{promo.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemMetrics;
