const Footer = () => (
  <footer className="flex justify-between items-center py-4 px-6 text-gray-500">
    <p>© 2024 Art Seekr All rights reserved.</p>
    <nav className="space-x-4">
      <a href="/terms" className="hover:underline">
        Terms of Service
      </a>
      <a href="/privacy" className="hover:underline">
        Privacy
      </a>
      <a href="/feedback" className="hover:underline">
            Feedback
          </a>
    </nav>
  </footer>
);

export default Footer;
