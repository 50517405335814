import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <span
      className="flex items-center text-blue-500 hover:text-blue-700 cursor-pointer mb-4"
      onClick={() => navigate("/")}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
          stroke-width="2"
        />
      </svg>
      <span className="text-lg font-medium">Back to Home</span>
    </span>
  );
};

export default BackButton;
