import { motion } from "framer-motion";
import { Label } from "../../../components/UI/label";

interface ExampleSearchProps {
  example: {
    uploadedImage: string;
    artworks: { id: number; path: string }[];
  };
  index: number;
}

const ExampleSearch = ({ example, index }: ExampleSearchProps) => (
  <section className="w-full py-12 md:py-16 text-center">
    <h2 className="text-2xl md:text-3xl font-bold mb-4 text-black">
      Example Searches
    </h2>
    <div className="text-lg text-gray-400 mb-4">
      <Label className="text-black text-lg">Uploaded Image</Label>
    </div>
    <div className="flex justify-center rounded-lg p-1 mt-4">
      <img
        src={example.uploadedImage}
        alt="search"
        className="rounded-lg object-cover"
        style={{ maxWidth: "200px", maxHeight: "200px" }}
      />
    </div>
    <div className="px-4 md:px-6 mt-6">
      <motion.div
        key={index}
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-2 w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        {example.artworks.map((artwork) => (
          <div
            key={artwork.id}
            className="rounded-lg p-1 flex items-center justify-center"
          >
            <img
              src={artwork.path}
              alt={artwork.id.toString()}
              className="rounded-lg w-full h-full object-cover object-top"
              style={{ maxWidth: "100%", maxHeight: "200px" }}
            />
          </div>
        ))}
      </motion.div>
    </div>
  </section>
);

export default ExampleSearch;
