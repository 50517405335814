import { searchResults } from "_mock/imageUploadResponse";
import { useState } from "react";

export function useImageUpload() {
  const [image, setImage] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        setImage(e.target.files[0]);
        setErrorMessage(null);
      }
    } catch (error) {
      console.error("Error selecting file: ", error);
      setErrorMessage("Failed to select file. Please try again.");
    }
  };

  const handleImageSearch = async (
    e: React.FormEvent,
    history: (path: string) => void
  ) => {
    e.preventDefault();
    if (!image) {
      setErrorMessage("Please upload an image before searching.");
      return;
    }

    const formData = new FormData();
    formData.append("file", image);

    try {
      const response = await fetch("https://artseekr.com/api/upload-image/", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      localStorage.setItem("uploadResult", JSON.stringify(data));
      setErrorMessage(null);
      history(`/search?query=${image?.name}`);
    } catch (error: any) {
      console.error("Error during image upload: ", error);
      setErrorMessage(
        error?.message || "Failed to process image. Please try again."
      );
      localStorage.setItem("uploadResult", JSON.stringify(searchResults));
    }
  };

  return { image, errorMessage, handleFileChange, handleImageSearch };
}
