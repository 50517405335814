export const searchResults = {
  message: "Image uploaded and processed successfully",
  results: [
    {
      id: 71559,
      title: "Starry Night",
      artist: "Vincent van Gogh",
      path: "/image2/1.jpg",
    },
    {
      id: 80725,
      title: "The Persistence of Memory",
      artist: "Salvador Dalí",
      path: "/image2/2.jpg",
    },
    {
      id: 5621,
      title: "The Scream",
      artist: "Edvard Munch",
      path: "/image2/3.jpg",
    },
    {
      id: 40062,
      title: "Girl with a Pearl Earring",
      artist: "Johannes Vermeer",
      path: "/image2/4.jpg",
    },
    {
      id: 77141,
      title: "The Night Watch",
      artist: "Rembrandt",
      path: "/image2/41967.jpg",
    },
    {
      id: 68176,
      title: "The Birth of Venus",
      artist: "Sandro Botticelli",
      path: "/image2/56239.jpg",
    },
    {
      id: 67005,
      title: "Mona Lisa",
      artist: "Leonardo da Vinci",
      path: "/image2/1.jpg",
    },
    {
      id: 44776,
      title: "Guernica",
      artist: "Pablo Picasso",
      path: "/image2/1.jpg",
    },
  ],
};
