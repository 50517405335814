import { useState, useEffect } from "react";
import { useLocation, useNavigate,useParams } from "react-router-dom";
import { Mountain } from "lucide-react";
import { Image } from "antd"; // Import the Ant Design Image component
import { formatString } from "lib/utils";
import Footer from "@components/UI/Footer";
import Header from "@components/UI/Header";

export default function PaintingPage() {
  const [painting, setPainting] = useState<any>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    // const getQueryParams = () => {
    //   const params = new URLSearchParams(location.search);
    //   return params.get("id");
    // };

    const fetchPainting = () => {
      if (id) {
        const results = JSON.parse(
          localStorage.getItem("uploadResult") || "{}"
        );

        // Check if 'results.results' exists and is an array
        if (!results?.results || !Array.isArray(results.results)) {
          navigate("/"); // Navigate to home if invalid
          return;
        }

        const paintingDetails = results.results.find(
          (item: any) => item.id === parseInt(id, 10)
        );

        if (paintingDetails) {
          setPainting({
            ...paintingDetails,
            medium: "Unknown",
            dimensions: "Unknown",
            location: "Unknown",
            description: "No description available",
          });
        } else {
          console.error("Painting not found");
          navigate("/"); // Navigate to home if painting not found
        }
      } else {
        navigate("/"); // Navigate to home if no id found in query params
      }
    };
    fetchPainting();
  }, [location.search, navigate]);

  const similarPaintings = [
    { id: 2, title: "The Night Café", artist: "Vincent van Gogh", year: 1888 },
    {
      id: 3,
      title: "Café Terrace at Night",
      artist: "Vincent van Gogh",
      year: 1888,
    },
    {
      id: 4,
      title: "Wheatfield with Crows",
      artist: "Vincent van Gogh",
      year: 1890,
    },
    { id: 5, title: "The Scream", artist: "Edvard Munch", year: 1893 },
  ];

  if (!painting) {
    return (
      <div className="flex justify-center items-center h-screen bg-white text-gray-500">
        Loading...
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-white text-black">
     <Header/>
      <main className="flex-1 py-12">
        <div className="container px-4 md:px-6">
          <div className="mb-6">
            <span
              className="flex items-center text-blue-500 hover:text-blue-700 cursor-pointer mb-4"
              onClick={() => navigate("/search")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {/* <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /> */}
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  strokeWidth="2"
                />
              </svg>
              <span className="text-lg font-medium">
                Back to Search Results
              </span>
            </span>
          </div>
          <div className="grid gap-10 lg:grid-cols-2">
            <div className="space-y-6">
              <div className="max-w-full h-[400px] flex justify-center items-center rounded-lg shadow-lg">
                <Image
                  src={painting.path}
                  alt={painting.title}
                  style={{ maxHeight: "400px", objectFit: "contain" }}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
            </div>
            <div className="space-y-6">
              <h1 className="text-4xl font-bold text-black">
                {formatString(painting.artist)}
              </h1>
              <div className="space-y-2">
                <p className="text-xl font-medium text-black">
                  {formatString(painting.genre)}
                </p>
                <p className="text-gray-500">{painting.year}</p>
              </div>
              {/* <div className="space-y-2">
                <p>
                  <strong className="text-black">Medium:</strong>{" "}
                  {painting.medium}
                </p>
                <p>
                  <strong className="text-black">Dimensions:</strong>{" "}
                  {painting.dimensions}
                </p>
                <p>
                  <strong className="text-black">Location:</strong>{" "}
                  {painting.location}
                </p>
              </div> */}
              <p className="text-gray-500">{painting.description}</p>
            </div>
          </div>
        </div>
      </main>
     <Footer/>
    </div>
  );
}
