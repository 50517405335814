import { useState, useCallback, useEffect } from "react";
import { SearchResult } from "../page";

const resultsPerPage = 10;

export function useSearchResults(page: number) {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSearchResults = useCallback(async () => {
    setIsLoading(true);
    try {
      const results = JSON.parse(localStorage.getItem("uploadResult") || "{}");
      if (results.results) {
        const start = (page - 1) * resultsPerPage;
        const end = start + resultsPerPage;

        const paginatedResults = results.results.slice(start, end);

        setSearchResults(paginatedResults);
        setTotalResults(results.results.length);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchSearchResults();
  }, [fetchSearchResults]);

  return { searchResults, totalResults, isLoading };
}
