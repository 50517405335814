import { Input } from "../../../components/UI/input";
import { Button } from "../../../components/UI/button";
import { Label } from "../../../components/UI/label";
import { Upload } from "lucide-react";

interface UploadFormProps {
  onSubmit: (e: React.FormEvent) => void;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  image: File | null;
  errorMessage: string | null;
}

const UploadForm = ({
  onSubmit,
  onFileChange,
  image,
  errorMessage,
}: UploadFormProps) => (
  <div className="w-full max-w-md space-y-4">
    <form onSubmit={onSubmit} className="flex flex-col items-center space-y-4">
      <div className="w-full">
        <Label htmlFor="picture" className="text-black text-lg">
          Search with an image
        </Label>
        <Input
          id="picture"
          type="file"
          accept="image/*"
          onChange={onFileChange}
          onClick={() => errorMessage && (errorMessage = null)}
          className="border-black py-2 px-4 rounded-lg w-full"
          style={{ border: "1px solid lightgrey" }}
        />
      </div>
      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
      <Button
        type="submit"
        className="bg-blue-500 text-white hover:bg-blue-400 py-2 px-4 rounded-lg flex items-center space-x-2"
        disabled={!image}
      >
        <Upload className="h-5 w-5" />
        <span>Search with Image</span>
      </Button>
    </form>
  </div>
);

export default UploadForm;
