import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useImageUpload } from "./hooks/useImageUpload";
import Header from "../../components/UI/Header";
import UploadForm from "./components/UploadForm";
import ExampleSearch from "./components/ExampleSearch";
import Footer from "../../components/UI/Footer";

const searchExamples = [
  {
    type: "image",
    query: "Uploaded Image",
    artworks: [
      { id: 1, path: "/image1/im1.jpg" },
      { id: 2, path: "/image1/im2.jpg" },
      { id: 3, path: "/image1/im3.jpg" },
      { id: 4, path: "/image1/im4.jpg" },
    ],
    uploadedImage: "/image1/image1.jpeg",
  },
  {
    type: "image",
    query: "Uploaded Image",
    artworks: [
      { id: 5, path: "/image2/im1.jpg" },
      { id: 6, path: "/image2/im2.jpg" },
      { id: 7, path: "/image2/im3.jpg" },
      { id: 8, path: "/image2/im4.jpg" },
    ],
    uploadedImage: "/image2/image2.jpg",
  },
  {
    type: "image",
    query: "Uploaded Image",
    artworks: [
      { id: 9, path: "/image4/im1.jpg" },
      { id: 10, path: "/image4/im2.jpg" },
      { id: 11, path: "/image4/im3.jpg" },
      { id: 12, path: "/image4/im4.jpg" },
    ],
    uploadedImage: "/image4/image4.jpeg",
  },
];

const Component = () => {
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0);
  const { image, errorMessage, handleFileChange, handleImageSearch } =
    useImageUpload();
  const history = useNavigate();

  useEffect(() => {
    localStorage.removeItem("uploadResult");
    const timer = setInterval(() => {
      setCurrentExampleIndex(
        (prevIndex) => (prevIndex + 1) % searchExamples.length
      );
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-custom-gradient text-white">
      <Header />
      <main className="flex-1 flex flex-col items-center justify-start space-y-8 py-8 md:py-12">
        <section className="text-center space-y-4">
          <h1 className="text-4xl md:text-5xl font-extrabold leading-tight tracking-tight text-black mt-6">
          Unlock a World of Art with AI
          </h1>
          <p className="text-lg md:text-xl text-black max-w-3xl mx-auto">
           Upload an image and discover visually similar artworks powered by advanced AI.<br/> 
           Dive into a curated gallery of pieces tailored to your artistic style.
          </p>
        </section>

        <UploadForm
          onSubmit={(e: any) => handleImageSearch(e, history)}
          onFileChange={handleFileChange}
          image={image}
          errorMessage={errorMessage}
        />

        <ExampleSearch
          example={searchExamples[currentExampleIndex]}
          index={currentExampleIndex}
        />
      </main>
      <Footer />
    </div>
  );
};

export default Component;