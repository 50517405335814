import { Button } from "../../../components/UI/button";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: PaginationProps) => (
  <div className="flex justify-between items-center mt-8">
    <Button
      onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
      disabled={currentPage === 1}
      variant="outline"
      className="bg-blue-500 border-none text-white hover:bg-blue-400 hover:text-white"
    >
      Previous
    </Button>
    <span className="text-sm text-black">
      Page {currentPage} of {totalPages}
    </span>
    <Button
      onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
      disabled={currentPage === totalPages}
      variant="outline"
      className="bg-blue-500 border-none text-white hover:bg-blue-400 hover:text-white"
    >
      Next
    </Button>
  </div>
);

export default Pagination;
