import { formatString } from "lib/utils";
import { SearchResult } from "../page";

interface ResultCardProps {
  result: SearchResult;
  onClick: (id: number, name: string) => void;
}

const ResultCard = ({ result, onClick }: ResultCardProps) => (
  <div
    key={result.id}
    className="bg-white rounded-lg shadow-lg p-3 max-w-full transform hover:scale-105 transition-transform duration-300 cursor-pointer"
    onClick={() => onClick(result.id, result.artist)}
  >
    <div className="relative w-full" style={{ paddingTop: "75%" }}>
      <img
        src={result.path}
        alt={result.artist}
        className="absolute top-0 left-0 w-full h-full object-cover object-top"
      />
    </div>
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-1 text-black">
        {formatString(result.artist)}
      </h2>
      <p className="text-sm text-gray-400">{formatString(result.genre)}</p>
    </div>
  </div>
);

export default ResultCard;
