import React, { useState } from "react";
import { Loader } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSearchResults } from "./hooks/useSearchResults";
import Header from "../../components/UI/Header";
import BackButton from "./components/BackButton";
import ResultCard from "./components/ResultCard";
import Pagination from "./components/Pagination";
import Footer from "../../components/UI/Footer";

export interface SearchResult {
  genre: string;
  id: number;
  title: string;
  artist: string;
  year: number;
  path: string;
}

const SearchResults = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { searchResults, totalResults, isLoading } =
    useSearchResults(currentPage);
  const navigate = useNavigate();
  const totalPages = Math.ceil(totalResults / 10);
  const handleResultClick = (id: number,name: string) => {
    navigate(`/painting/${id}/${name}`);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-1 py-12">
        <div className="px-4 md:px-6">
          <BackButton />
          <h1 className="text-3xl font-bold mb-6 text-black">Search Results</h1>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <Loader className="h-8 w-8 animate-spin" />
              <span className="ml-2">Loading...</span>
            </div>
          ) : searchResults.length > 0 ? (
            <>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {searchResults.map((result) => (
                  <ResultCard
                    key={result.id}
                    result={result}
                    onClick={handleResultClick}
                  />
                ))}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </>
          ) : (
            <p className="text-black">No results found.</p>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default SearchResults;
