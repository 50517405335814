import React from "react";
import { Mountain } from "lucide-react";
import { Button } from "../../components/UI/button";
import { useNavigate } from "react-router-dom";
import Footer from "@components/UI/Footer";
import Header from "@components/UI/Header";

export default function TermsAndConditions() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Header */}
    <Header/>

      {/* Main content */}
      <main className="flex-1 py-12">
        <div className="px-4 md:px-6">
        <span className="flex items-center text-blue-500 hover:text-blue-700 cursor-pointer mb-4" onClick={() => navigate("/")} >
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      {/* <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /> */}
      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"  stroke-width="2"/>

    </svg>

    <span className="text-lg font-medium">Back</span>
  </span>
          <h1 className="text-3xl font-bold mb-6 text-black">
            Terms of Service
          </h1>

          <section className="text-black">
            <p className="mb-4">
              Important! Your access to this Website is subject to legally
              binding terms and conditions.
            </p>
            <p className="mb-4">
              Carefully read all of the following terms and conditions before
              proceeding. Accessing this Website is the equivalent of your
              signature and indicates your acceptance of these terms and
              conditions and that you intend to be legally bound by them.
            </p>
            <p className="mb-4">
              If you do not agree with these terms and conditions, you must exit
              the Website. These Terms of Use (the “Agreement”) is an agreement
              between you (“you”) and Art Seekr Apps who is the owner of the
              Website (“us”, “we”, “Art Seekr Apps”).
            </p>
            <p className="mb-4">
              By using the Art Seekr Apps website, www.artseekr.com, or
              accessing any information, services, assessments, resources, or
              tools via the Art Seekr Apps website (collectively, the
              “Website”), you agree, without limitation, to comply with these
              Terms of Use.
            </p>
            <p className="mb-4">
              The effective date of this Agreement is the earlier of the
              following dates: (i) the date when you first access or use this
              Website; or (ii) the date when you click on the “I Understand and
              Agree” button appearing on the Website.
            </p>
            <p className="mb-4">
              This Agreement does not alter in any way the terms or conditions
              of any other agreement you may have with Art Seekr Apps.
            </p>
            <p className="mb-4">
              If you do not agree with the Terms of Use as an unregistered user
              (view only), please leave the Website immediately.
            </p>
            <p className="mb-4">
              Otherwise, your continued use of the Website signals your
              agreement to abide by these Terms of Use.
            </p>
            <p className="mb-4">
              By accessing and/or using this Website, you acknowledge that you
              have not been previously suspended or removed from this Website by
              Art Seekr Apps for any reason unless, since the date of your
              suspension or removal, you have received written authorization
              from Art Seekr Apps to access this Website.
            </p>
            <p className="mb-4">
              By accessing and/or using this Website, you acknowledge that you
              have read, understand, and agree to comply with these Terms of
              Use, and all applicable laws.
            </p>
            <p className="mb-4">
              If you are accessing and/or using the Website on behalf of any
              entity, you further represent and warrant that you are authorized
              to accept this Agreement on such entity’s behalf, and that such
              entity agrees to indemnify Art Seekr Apps in accordance with this
              Agreement.
            </p>
            <p className="mb-4">
              Some aspects of the Website require the collection, use, or
              disclosure of personal information. For further information about
              how your personal information (including your payment details) is
              collected, used, and disclosed and how it is safeguarded by Art
              Seekr, please refer to the Privacy Policy.
            </p>
            <p className="mb-4">
              As a condition of using the Website, you agree to refrain from any
              defamatory, harassing, negligent, inaccurate, offensive, or
              illegal conduct. Art Seekr Apps reserves the right, in its sole
              discretion, to restrict, suspend, or terminate your access to all
              or part of the Website without prior notice and without liability.
            </p>
            <p className="mb-4">
              The purpose of the Website is to provide general information about
              our proprietary applications (“Information”) and access to our
              applications and other services, resources, and tools
              (“Resources”). Art Seekr Apps assumes no responsibility, nor
              liability for the Information or Resources on the Website, nor for
              any claims, damages, or losses resulting from any reliance on or
              use of Resources.
            </p>
            <p className="mb-4">
              Art Seekr Apps’s liability is limited except where not permitted
              by applicable law. Art Seekr Apps makes great efforts to provide
              accurate information on the Website.
            </p>
            <p className="mb-4">
              However, Art Seekr Apps disclaims—and you release Art Seekr Apps
              from any liability regarding—errors, inaccuracies, and omissions
              of the Website.
            </p>
            <p className="mb-4">
              Art Seekr Apps reserves the right to correct any errors,
              inaccuracies, or omissions and to change or update Information or
              Resources at any time without prior notice. Art Seekr Apps makes
              no guarantees whatsoever as to the completeness, timeliness,
              correctness, or accuracy of the Information or Resources available
              through the Website.
            </p>
            <p className="mb-4">
              If you believe any portion of the Website includes an error or
              inaccuracy, please notify Art Seekr Apps. We do not guarantee that
              our Website, Information, or Resources will be secure or free from
              bugs or viruses.
            </p>
            <p className="mb-4">
              You are responsible for configuring your information technology,
              computer programs, and platform to access our Website.
            </p>
            <p className="mb-4">
              You should use your own virus protection software. You agree that
              it is solely your responsibility to ensure that your access to
              this Website complies with the laws of your jurisdiction.
            </p>
            <p className="mb-4">
              You are responsible for all software, hardware, services (such as
              Internet service), and equipment necessary to access and use the
              Website, including all related expenses, if any.
            </p>

            <h2 className="text-xl font-semibold mb-4">
              Intellectual Property Rights
            </h2>
            <p className="mb-4">
              The Information and Resources provided through the Website are
              protected by copyright and may only be used in accordance with
              these Terms of Use.
            </p>
            <p className="mb-4">
              Unless otherwise specified, title to the Information and Resources
              rests with Art Seekr Apps, or with third parties who have
              contributed to the Information and/or Resources, except for
              information and resources, including video excerpts, that are
              shared on this Website that are not owned by Art Seekr Apps and
              that are otherwise made publicly available by the owners of the
              title therein through other sources.
            </p>
            <p className="mb-4">
              Any unauthorized use of the Information or Resources may violate
              copyright, trademark, and other laws. This Website, including all
              software code, its content and design (including all Information,
              Resources, and Contributions), is protected under applicable
              intellectual property and other laws, including without limitation
              the laws of the US and other countries.
            </p>
            <p className="mb-4">
              All Information, Resources, Contributions (after these are
              provided to the Website), and all intellectual property rights
              therein, are the property of Art Seekr Apps unless otherwise
              agreed in a prior written agreement with Art Seekr Apps. You do
              not acquire ownership rights to any Information, Resources, or
              Contributions, whether viewed or otherwise accessed, through this
              Website.
            </p>
            <p className="mb-4">
              Except as otherwise provided herein, none of the Information,
              Resources, or Contributions may be used, copied, reproduced,
              distributed, reverse-engineered, republished, downloaded,
              modified, sold, rented, shared, displayed, posted, or transmitted
              in any form or by any means, including, but not limited to,
              electronic or mechanical copying, photocopying, recording, or
              otherwise, without Art Seekr Apps’s express prior written
              permission.
            </p>
            <p className="mb-4">
              You acknowledge that the unauthorized use of the Website could
              cause irreparable harm to us, our partners, suppliers, licensors,
              members, and/or customers and that in the event of an unauthorized
              use, we will be entitled to any and all remedies at law or in
              equity, including without limitation injunctive relief, without
              the necessity of proving monetary damages or posting a bond or
              other security.
            </p>
            <p className="mb-4">
              This Website contains trademarks, service marks, trade dress,
              designs, logos, product and service names, and graphics
              (collectively, “Trademarks”) that are owned by Art Seekr Apps, or
              by third parties. You are not authorized to display, copy, or use
              the Trademarks in any manner without the prior written permission
              of the rights owner.
            </p>
            <p className="mb-4">
              All Information, Resources, and Contributions, and the selection,
              arrangement, and presentation thereof, and the overall design,
              “look and feel”, colour combinations, and other graphical elements
              of the Website are the exclusive property of us, or our partners,
              licensors, or suppliers (collectively the “Website Content”).
            </p>
            <p className="mb-4">
              We, or our partners, licensors, or suppliers, own all rights,
              title, and interest in and to the Website Content, including
              without limitation all copyright and other intellectual property
              and proprietary rights in and to the Website Content.
            </p>

            <h2 className="text-xl font-semibold mb-4">
              Your Consent to our privacy policy
            </h2>
            <p className="mb-4">
              By agreeing to this Agreement, you agree to the terms of our
              Cookie Policy and our Privacy Policy, which are expressly
              incorporated herein.
            </p>
            <p className="mb-4">
              Before using this Website, please carefully review the Cookie
              Policy and the Privacy Policy. Use of the Website will be handled
              in accordance with the Cookie Policy. All information provided to
              us as a result of your use of this Website will be handled in
              accordance with the Privacy Policy.
            </p>
            <p className="mb-4">
              To the extent there are inconsistencies between this Agreement and
              either of the Cookie Policy or the Privacy Policy, this Agreement
              controls.
            </p>
            <p className="mb-4">
              You agree that when using the Website you will comply with all
              applicable laws and regulations, including, without limitation,
              those relating to the Internet, data, email, messaging, privacy,
              and the transmission of technical data exported from the US or the
              country in which you reside.
            </p>
            <p className="mb-4">
              You further consent to Art Seekr Apps providing information other
              than personal information, relating to you and your use of the
              Website to facilitate the function of the Website and to allow
              users to utilize the Website.
            </p>

            <h2 className="text-xl font-semibold mb-4">
              YOUR USE OF THE WEBSITE
            </h2>
            <p className="mb-4">
              Subject to the terms of these Terms of Use, you are granted a
              limited, personal, non-exclusive, non-sublicensable,
              non-assignable, non-transferable, and revocable licence to access
              and use the Website and related materials that are owned by Art
              Seekr solely for your own non-commercial use.
            </p>
            <p className="mb-4">
              Except as expressly provided, all rights in the Website and such
              related materials are reserved. Nothing contained in these Terms
              of Use or on the Website shall be construed as conferring by
              implication, estoppel, or otherwise any licence or right for any
              intellectual property of Art Seekr Apps or any third party, other
              than is expressly provided herein.
            </p>
            <p className="mb-4">
              Except as expressly permitted by these Terms of Use, in connection
              with the use of the Website, you may not:
            </p>
            <ul className="list-disc mb-4 ml-6">
              <li>
                Alter or modify the Website, or make any electronic
                reproduction, adaptation, distribution, performance, or display
                of the Website, or any portion thereof, except to the extent
                required for the limited purpose of reviewing material on the
                Website;
              </li>
              <li>
                Sell, rent, lease, transfer, distribute, broadcast, display,
                provide, or otherwise assign to any third party any rights to
                the Website, or related materials;
              </li>
              <li>
                Remove or modify any proprietary notice or labels on the
                Website, or related materials, including author attribution and
                copyright notices, or use any of our trademarks as meta-tags on
                any other website;
              </li>
              <li>
                Use the Website for any unlawful purpose, including but not
                limited to sending unsolicited or unauthorised advertising, or
                for any other purpose that could result in harm to us or to any
                third party;
              </li>
              <li>
                Post or transmit any false, misleading, fraudulent, defamatory,
                obscene, or otherwise objectionable information or materials, or
                any materials or information that infringes any intellectual
                property rights of others;
              </li>
              <li>
                Disrupt or interfere with any servers or networks used in
                connection with the Website;
              </li>
              <li>
                Engage in any activity that may harm, disrupt, or overload the
                Website or that might interfere with the use of the Website by
                others;
              </li>
              <li>
                Attempt to gain unauthorised access to any part of the Website
                or its related systems or networks.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mb-4">
              Restrictions and conditions use{" "}
            </h2>
            <p className="mb-4">
              Your use of this Website is conditioned on your compliance with
              all applicable laws and regulations and these Terms of Use. By
              using this Website, you agree:
            </p>
            <ul className="list-disc mb-4 ml-6">
              <li>
                To provide accurate, complete, and current information about
                yourself as required for your use of this Website;
              </li>
              <li>
                To notify Art Seekr Apps immediately of any unauthorised use of
                your password or account, or any other breach of security;
              </li>
              <li>
                To take responsibility for all activities that occur under your
                account;
              </li>
              <li>
                To comply with the policies, rules, and guidelines that may be
                adopted by Art Seekr Apps from time to time;
              </li>
              <li>
                To respect the privacy of other users and not to misuse or share
                information collected from the Website.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mb-4">Termination </h2>
            <p className="mb-4">
              Art Seekr Apps reserves the right, in its sole discretion, to
              terminate or suspend your access to the Website at any time, with
              or without cause or notice, including, without limitation, for any
              violation of these Terms of Use. In the event of termination, you
              must immediately stop using the Website and must destroy any
              copies of materials you have obtained from the Website.
            </p>
            <h2 className="text-xl font-semibold mb-4">Indemnity </h2>
            <p className="mb-4">
              You agree to indemnify and hold harmless Art Seekr Apps, its
              affiliates, officers, directors, employees, agents, and third
              parties, from and against any claims, liabilities, damages,
              losses, costs, and expenses, including reasonable attorneys' fees,
              arising out of or related to your use of the Website, your breach
              of these Terms of Use, or any violation of any law or the rights
              of any third party.
            </p>
            <h2 className="text-xl font-semibold mb-4">
              Limitation of Liability
            </h2>
            <p className="mb-4">
              To the fullest extent permitted by law, Art Seekr Apps shall not
              be liable for any indirect, incidental, special, consequential, or
              punitive damages, or any loss of profits or revenues, whether
              incurred directly or indirectly, or any loss of data or other
              intangible losses, resulting from (a) your use of the Website; (b)
              your inability to access or use the Website; (c) any unauthorized
              access to or use of the Website; or (d) any errors or omissions in
              any content.
            </p>
            <h2 className="text-xl font-semibold mb-4">Disclaimer</h2>
            <p className="mb-4">
              The Website is provided on an “as-is” and “as-available” basis,
              without warranties of any kind, either express or implied. Art
              Seekr disclaims all warranties, including but not limited to,
              implied warranties of merchantability, fitness for a particular
              purpose, and non-infringement. Art Seekr Apps does not warrant
              that the Website will be uninterrupted or error-free, or that any
              defects will be corrected.
            </p>
            <h2 className="text-xl font-semibold mb-4">
              Changes to these terms
            </h2>
            <p className="mb-4">
              Art Seekr Apps reserves the right to modify or revise these Terms
              of Use at any time without prior notice. Your continued use of the
              Website following any changes signifies your acceptance of those
              changes.
            </p>
            <h2 className="text-xl font-semibold mb-4">Applicable Law</h2>
            <p className="mb-4">
              These Terms of Use shall be governed by and construed in
              accordance with the laws of the country you reside or the US,
              without regard to its conflict of law principles. Any legal action
              or proceeding arising out of or related to these Terms of Use
              shall be brought exclusively in the courts.
            </p>

            <h2 className="text-xl font-semibold mb-4">Entire Agreement</h2>
            <p className="mb-4">
              These Terms of Use, together with the Privacy Policy and any other
              legal notices published by Art Seekr Apps on the Website,
              constitute the entire agreement between you and Art Seekr Apps
              regarding the Website and supersede all prior or contemporaneous
              communications and proposals, whether electronic, oral, or
              written, between you and Art Seekr Apps.
            </p>
            <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
            <p className="mb-4">
              If you have any questions about these Terms of Use, please contact
              us at support@artseekr.com
            </p>
          </section>
        </div>
      </main>

      {/* Footer */}
     <Footer/>
    </div>
  );
}
