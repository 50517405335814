import React from "react";
import { Mountain } from "lucide-react";
// import { Button } from "../../components/UI/button";
import { useNavigate } from "react-router-dom";
import Header from "@components/UI/Header";
import Footer from "@components/UI/Footer";

export default function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Header */}
   <Header/>

      {/* Main content */}
      <main className="flex-1 py-12">
        <div className="px-4 md:px-6">
        <span className="flex items-center text-blue-500 hover:text-blue-700 cursor-pointer mb-4" onClick={() => navigate("/")} >
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      {/* <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /> */}
      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"  stroke-width="2"/>

    </svg>

    <span className="text-lg font-medium">Back</span>
  </span>
        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
  </svg> */}

          {/* <Button
            onClick={() => navigate("/")}
            className="bg-blue-500 border-none text-white hover:bg-blue-400 hover:text-white mb-6"
          >
            Back to Home
          </Button> */}
          <h1 className="text-3xl font-bold mb-6 text-black">Privacy Policy</h1>

          <section className="text-black">
            {/* Introduction */}
            <h2 className="text-xl font-semibold mb-2">Introduction</h2>
            <p className="mb-4">
              Your privacy is important to us. This policy outlines your rights
              regarding your personal data and explains how we collect, use,
              disclose, transfer, and store your information.
            </p>
            <p className="mb-4">
              When we refer to "Art Seekr", "we", "our", or "us", it means
              Art Seekr, which is the entity responsible for processing
              your personal data.
            </p>
            <p className="mb-4">
              When we refer to "Website" or "Products", it includes all of our
              websites, software applications, and related services.
            </p>
            <p className="mb-4">
              When we say "Personal Data", it refers to all personal information
              or personally identifiable information as defined by applicable
              laws.
            </p>
            <p className="mb-4">
              When we refer to "you" or "user", it means visitors, customers,
              and consumers accessing our Website or Products.
            </p>
            <p className="mb-4">
              We act as both a "Data Controller" and "Data Processor" of
              Personal Data covered by this Privacy Policy, depending on the
              features you choose to use.
            </p>

            {/* Data Collection Principles */}
            <h2 className="text-xl font-semibold mb-2">
              Data Collection Principles
            </h2>
            <ul className="list-disc mb-4 ml-6">
              <li>We collect only the necessary information.</li>
              <li>
                We use Personal Data solely for the purposes specified in this
                Privacy Policy.
              </li>
              <li>We do not retain Personal Data longer than necessary.</li>
              <li>
                We do not disclose Personal Data except as outlined in this
                Privacy Policy.
              </li>
              <li>
                By accepting this Privacy Policy, you consent to the collection,
                use, and storage of Personal Data as described. You may withdraw
                your consent at any time by contacting us through the provided
                methods.
              </li>
              <li>
                If you disagree with our Privacy Policy, please do not use our
                Website and Products. By accessing or using them, you agree to
                this Privacy Policy.
              </li>
            </ul>

            {/* Information We Collect */}
            <h2 className="text-xl font-semibold mb-2">
              Information We Collect
            </h2>
            <h3 className="text-lg font-semibold mb-2">
              Information You Provide:
            </h3>
            <ul className="list-disc mb-4 ml-6">
              <li>
                Contact Information: Optional email address, name, company name,
                etc., for communication and support.
              </li>
              <li>
                Account Information: Required for Products with per-user
                licences, including name, email, and company details.
              </li>
              <li>
                Newsletters: Optional subscription to newsletters, with an easy
                opt-out option.
              </li>
              <li>
                Comments and Correspondence: Any comments or mail you send us.
              </li>
              <li>Surveys: Responses to surveys for research purposes.</li>
            </ul>
            <h3 className="text-lg font-semibold mb-2">
              Information We Receive from Others or Shared or Disclosed:
            </h3>
            <p className="mb-4">
              We may receive information about you from various sources,
              including:
            </p>
            <ul className="list-disc mb-4 ml-6">
              <li>
                Third parties such as business partners or social media
                platforms.
              </li>
              <li>
                Third-party services you use to connect with our services may
                share your profile information or usage data.
              </li>
              <li>
                Public databases and other external sources like marketing
                partners or third-party providers.
              </li>
            </ul>
            <p className="mb-4">The information we receive helps us to:</p>
            <ul className="list-disc mb-4 ml-6">
              <li>Enhance your experience with our services.</li>
              <li>Improve our offerings and services.</li>
              <li>Manage and optimise our communications with you.</li>
            </ul>
            <p className="mb-4">We may share your personal data with:</p>
            <ul className="list-disc mb-4 ml-6">
              <li>
                Trusted partners and service providers who assist in operating
                our business and delivering services.
              </li>
              <li>
                Marketing and analytics partners to help us conduct and improve
                our marketing efforts.
              </li>
            </ul>
            <p className="mb-4">
              All third parties we share your information with are:
            </p>
            <ul className="list-disc mb-4 ml-6">
              <li>
                Bound by contractual obligations to maintain the confidentiality
                of your information.
              </li>
              <li>
                Permitted to use your information only for the specific purposes
                we have defined.
              </li>
            </ul>
            <h3 className="text-lg font-semibold mb-2">
              Information Collected Automatically:
            </h3>
            <ul className="list-disc mb-4 ml-6">
              <li>
                Log Files: Automatically reported by your browser, including IP
                address, browser type, and more.
              </li>
              <li>
                Cookies: Used to enhance your browsing experience. Cookies are
                collected only with your consent.
              </li>
              <li>
                Analytics: Installation and activation logs for our in-house
                purposes.
              </li>
            </ul>

            {/* How We Use Your Personal Data */}
            <h2 className="text-xl font-semibold mb-2">
              How We Use Your Personal Data
            </h2>
            <p className="mb-4">
              We use your Personal Data for the following purposes:
            </p>
            <ul className="list-disc mb-4 ml-6">
              <li>To present our Website and its content.</li>
              <li>To provide information about our Products.</li>
              <li>
                To manage licences, subscriptions, and provide customer support.
              </li>
              <li>To notify you of updates and changes.</li>
              <li>To allow participation in interactive features.</li>
            </ul>

            <h3 className="text-lg font-semibold mb-2">
              Purposes of Processing Personal Data:
            </h3>
            <ul className="list-disc mb-4 ml-6">
              <li>
                Providing and Maintaining Services: To deliver the products and
                services you have requested and ensure they function correctly.
              </li>
              <li>
                Communication: To communicate with you, including responding to
                inquiries, sending service-related notices, and providing
                customer support.
              </li>
              <li>
                Personalisation: To tailor our services to your preferences and
                provide a customised experience.
              </li>
              <li>
                Marketing: To send you promotional messages, offers, and other
                information that may be of interest to you, where permitted by
                law.
              </li>
              <li>
                Analytics and Improvements: To analyse usage trends and improve
                our services, including troubleshooting, data analysis, testing,
                research, and survey purposes.
              </li>
              <li>
                Compliance: To comply with legal obligations, enforce our terms
                and conditions, and protect the rights, property, or safety of
                our users, employees, and others.
              </li>
            </ul>

            {/* Sharing Your Personal Data */}
            <h2 className="text-xl font-semibold mb-2">
              Sharing Your Personal Data
            </h2>
            <p className="mb-4">
              We do not sell or disclose your Personal Data except as outlined
              in this policy. We may share your data with trusted third-party
              service providers, such as payment processors, customer support
              services, cloud services, and analytics providers, under strict
              confidentiality agreements.
            </p>
            <p className="mb-4">
              Information We Receive from Others or Share or Disclose:
            </p>
            <ul className="list-disc mb-4 ml-6">
              <li>
                We may receive information about you from other sources,
                including third parties, business partners, or social media
                platforms. For example:
              </li>
              <li>
                If you use a third-party service to connect with our services,
                that service may share certain information with us, such as your
                profile information or usage data.
              </li>
              <li>
                We may also receive information about you from public databases,
                marketing partners, and other third-party providers to enhance
                your experience, improve our offerings, and manage our
                communications.
              </li>
              <li>
                We may share your personal data with our trusted partners and
                service providers who assist us in operating our business,
                delivering our services, or conducting marketing and analytics.
                These third parties are bound by contractual obligations to keep
                your information confidential and use it only for the purposes
                we specify.
              </li>
            </ul>

            {/* We Do Not Collect */}
            <h2 className="text-xl font-semibold mb-2">We Do Not Collect</h2>
            <p className="mb-4">
              We do not collect sensitive personal information such as social
              security numbers, health data, or financial account details unless
              explicitly provided by you for specific purposes. We also do not
              intentionally collect information from children under the age of
              13. If we become aware that we have inadvertently gathered such
              information, we will take immediate steps to delete it from our
              records.
            </p>

            {/* We Do Not Use */}
            <h2 className="text-xl font-semibold mb-2">We Do Not Use</h2>
            <p className="mb-4">
              We do not use your personal data for any purposes other than those
              explicitly stated in this Privacy Policy. We do not engage in
              activities such as selling your data to third parties, using your
              data for unsolicited marketing, or sharing your data without your
              consent, except as required by law.
            </p>

            {/* Lawful Basis for Processing Your Personal Data */}
            <h2 className="text-xl font-semibold mb-2">
              Lawful Basis for Processing Your Personal Data
            </h2>
            <ul className="list-disc mb-4 ml-6">
              <li>
                Consent: We process your data with your explicit consent,
                particularly for marketing purposes or where required by law.
                You have the right to withdraw your consent at any time.
              </li>
              <li>
                Contract Performance: Processing is necessary for the
                performance of a contract to which you are a party, such as
                providing the services you have requested.
              </li>
              <li>
                Legitimate Interests: We process your data based on our
                legitimate interests, such as improving our services, securing
                our platforms, and conducting marketing activities, provided
                that these interests are not overridden by your rights and
                freedoms.
              </li>
              <li>
                Legal Obligations: We process your personal data to comply with
                applicable laws and regulations, such as tax laws or regulatory
                requirements.
              </li>
            </ul>

            {/* How Long We Store Your Personal Data */}
            <h2 className="text-xl font-semibold mb-2">
              How Long We Store Your Personal Data
            </h2>
            <p className="mb-4">
              We retain your personal data only for as long as necessary to
              fulfil the purposes for which it was collected, including any
              legal, accounting, or reporting requirements. The retention period
              varies depending on the type of data and the purposes of
              processing. For example:
            </p>
            <ul className="list-disc mb-4 ml-6">
              <li>
                Account Information: We retain your account information for as
                long as your account is active or as needed to provide you with
                services.
              </li>
              <li>
                Marketing Data: We retain marketing data until you opt out or
                withdraw your consent.
              </li>
              <li>
                Legal Compliance: We retain records as required by law or for
                the defence of legal claims.
              </li>
            </ul>
            <p className="mb-4">
              Once the retention period expires, we securely delete or anonymise
              your personal data.
            </p>

            {/* Our Steps to Be Secure */}
            <h2 className="text-xl font-semibold mb-2">
              Our Steps to Be Secure
            </h2>
            <p className="mb-4">
              To ensure the security of your personal data, we implement
              industry-standard practices, including encryption, secure access
              protocols, and regular security audits. Our team is trained to
              handle data with the highest level of care, and we continually
              update our security measures to guard against unauthorised access,
              data breaches, and other potential threats.
            </p>

            {/* Your Role in Data Safety */}
            <h2 className="text-xl font-semibold mb-2">
              Your Role in Data Safety
            </h2>
            <p className="mb-4">
              While we take significant steps to secure your personal data, you
              also play an important role in maintaining data safety. We
              encourage you to use strong, unique passwords for your accounts,
              avoid sharing your login credentials, and stay vigilant against
              phishing attempts and other forms of online fraud. If you suspect
              any unauthorised activity involving your personal data, please
              contact us immediately.
            </p>

            {/* Data Breaches */}
            <h2 className="text-xl font-semibold mb-2">Data Breaches</h2>
            <p className="mb-4">
              In the unlikely event of a data breach, we will take immediate
              action to mitigate the impact. This includes promptly notifying
              affected individuals, conducting a thorough investigation, and
              working to prevent future incidents. We are committed to
              transparency and will keep you informed of any significant
              developments related to your personal data’s security.
            </p>

            {/* International Personal Data Transfers */}
            <h2 className="text-xl font-semibold mb-2">
              International Personal Data Transfers
            </h2>
            <p className="mb-4">
              If we transfer your personal data outside of your home country, we
              ensure that appropriate safeguards are in place to protect your
              data in compliance with applicable laws. This may include relying
              on standard contractual clauses, obtaining your explicit consent,
              or other legal mechanisms to ensure your data is handled securely
              and lawfully.
            </p>

            {/* Third-Party Websites */}
            <h2 className="text-xl font-semibold mb-2">Third-Party Websites</h2>
            <p className="mb-4">
              Our website may contain links to third-party websites that are not
              operated by us. We are not responsible for the privacy practices
              or content of these external sites. We encourage you to review the
              privacy policies of any third-party websites you visit to
              understand how they collect, use, and protect your personal data.
            </p>

            {/* Your Rights */}
            <h2 className="text-xl font-semibold mb-2">Your Rights</h2>
            <p className="mb-4">You have the right to:</p>
            <ul className="list-disc mb-4 ml-6">
              <li>Request access to and update your Personal Data.</li>
              <li>Be forgotten (request deletion of your data).</li>
              <li>Restrict the processing of your data.</li>
              <li>
                Withdraw your consent at any time without affecting the
                lawfulness of prior processing.
              </li>
              <li>
                Object to the processing of your data based on our legitimate
                interests.
              </li>
            </ul>

            {/* Children's Privacy */}
            <h2 className="text-xl font-semibold mb-2">Children's Privacy</h2>
            <p className="mb-4">
              Our services are not intended for use by children under the age of
              13 (or the equivalent minimum age in your jurisdiction), and we do
              not knowingly collect personal data from children. If we become
              aware that we have inadvertently collected personal data from a
              child without verified parental consent, we will take steps to
              delete that information within a reasonable time, but no later
              than required under the applicable law.
            </p>
            <p className="mb-4">
              If you believe that we may have collected information from a
              child, please contact us immediately so that we can address the
              situation.
            </p>

            {/* Security */}
            <h2 className="text-xl font-semibold mb-2">Security</h2>
            <p className="mb-4">
              We take reasonable steps to protect your Personal Data from loss,
              misuse, and unauthorised access.
            </p>

            {/* Changes to This Policy */}
            <h2 className="text-xl font-semibold mb-2">
              Changes to This Policy
            </h2>
            <p className="mb-4">
              We may update this Privacy Policy periodically. We will notify you
              of significant changes by posting the updated policy on our
              Website.
            </p>

            {/* Contact Us */}
            <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
            <p className="mb-4">
              For any questions or concerns about this Privacy Policy, please
              contact us at{" "}
              <a
                href="mailto:support@artseekerapps.com"
                className="text-blue-600 hover:underline"
              >
                support@artseekr.com
              </a>
              .
            </p>
          </section>
        </div>
      </main>

      {/* Footer */}
    <Footer/>
    </div>
  );
}
