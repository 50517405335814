import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SearchResults from "./pages/search/page";
import ItemMetrics from "./pages/metrics/page";
import PaintingPage from "./pages/art/page";
import Home from "./pages/home/page";
import TermsAndConditions from "pages/terms/page";
import Feedback from "pages/feedback";
import PrivacyPolicy from "pages/privacy/page";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/painting/:id/:name?" element={<PaintingPage />} />
        <Route path="/metrics" element={<ItemMetrics />} />
        <Route path="/search" element={<SearchResults />} />PrivacyPolicy
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/feedback" element={<Feedback />} />
      </Routes>
    </Router>
  );
}

export default App;
