import React, {useState} from "react";
import { Mountain } from "lucide-react";
// import { Button } from "../../components/UI/button";
import { useNavigate } from "react-router-dom";
import Header from "@components/UI/Header";
import Footer from "@components/UI/Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Feedback() {
  const navigate = useNavigate();

  // State to manage loading
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      about: event.target.about.value,
      feedback: event.target.feedback.value,
    };

    
    try {
      const response = await fetch("https://artseekr.com/api/feedback", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",  // Set the content type to JSON
          },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success('Feedback submitted successfully!');
        event.target.reset();
      } else {
        toast.error('Error submitting form!');
      }
    } catch (error) {
      toast.error('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Header */}
    <Header/>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Main content */}
      <main className="flex-1 py-12">
        <div className="px-4 md:px-6">
        <span className="flex items-center text-blue-500 hover:text-blue-700 cursor-pointer mb-4" onClick={() => navigate("/")} >
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      {/* <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /> */}
      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"  stroke-width="2"/>

    </svg>

    <span className="text-lg font-medium">Back</span>
  </span>
          <h1 className="flex justify-center items-center w-full text-3xl font-bold mb-6 text-black">Feedback Form</h1>

       {/* Card background */}
       <div className="flex justify-center items-center w-full ">
            <div className="shadow-lg rounded-lg p-8 w-full md:w-1/2 lg:w-5/12 border border-gray-200 bg-[#d3d3d35e]">
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    className="form-input w-full rounded border border-gray-300 p-2"
                    name="name"
                    type="text"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    className="form-input w-full rounded border border-gray-300 p-2"
                    name="email"
                    type="email"
                    placeholder="Your email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    className="form-input w-full rounded border border-gray-300 p-2"
                    name="about"
                    type="about"
                    placeholder="How did you hear about us?"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-textarea w-full rounded-md border border-gray-300 p-2"
                    name="feedback"
                    placeholder="Your Feedback"
                    rows={6}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 relative float-right"
                  disabled={loading}
                >
                  {loading ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M12 2a10 10 0 0 1 0 20 10 10 0 0 1 0-20"
                        />
                      </svg>
                      Sending...
                    </span>
                  ) : (
                    'Send Now'
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
  <Footer/>
    </div>
  );
}
